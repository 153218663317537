import Navbar from "../Navbar/Navbar";
import LangContext from "../LangContext";
import { useState } from "react";
import "./ErrorPage.sass";
import Footer from "../Footer/Footer";

function ErrorPage() {
    // const userLang = (navigator.language || navigator.userLanguage) == 'zh-TW' ? 'ch' : 'en';
    const userLang = 'en';
    const storedLang = localStorage.getItem('lang');
    const [lang, setLang] = useState(storedLang ?? userLang);

    function updateLang(lang) {
        setLang(lang);
        localStorage.setItem('lang', lang);
    }

    return (
        <LangContext.Provider value={lang}>
            <div className='content'>
                <div active="" className="access-404">
                    <div role="404">404</div>
                    <div>
                        <div />
                        <div role="text">Not Found</div>
                    </div>
                </div>
            </div>
            <Navbar updateLang={updateLang} />
            <Footer />
        </LangContext.Provider>
    );
}

export default ErrorPage;
